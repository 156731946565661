import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { kebabCase } from 'lodash'

import Layout from '../components/Layout'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box';


class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount

    const tagDescription = `「${tag}」タグが付いたアイデア`

    return (
      <Layout>
        <Helmet title={`${tag}タグ | ${title}`} />
        <section className="Section">
          <Container>
            <div className="PageTitle-section">
              <h3 className="TextCenter">
              {tag}<span>{tagDescription}</span>
              </h3>
            </div>
            <Grid container spacing={1}>
              {
                posts &&
                posts.map(({ node: post }, index ) => (
                <Grid item xs={12} md={4} key={post.fields.slug}>
                  {index <= 10? (
                    <Card>
                      <CardActionArea href={post.fields.slug}>
                        <CardContent className="CardContent-root">
                          <Box mb={1} className="CardHeader">
                             {post.frontmatter.tags && post.frontmatter.tags.length ? (
                                <p className="CardItem-tag">
                                  <span>{post.frontmatter.tags[0]}</span>
                                </p>
                              ) : null}
                              <p className="CardItem-date">
                                {post.frontmatter.date}
                              </p>
                          </Box>
                          <Box mb={1}>
                            <h2 className="CardItem-title">
                              {post.frontmatter.title}
                            </h2>
                          </Box>
                          <Box mb={1}>
                            <p className="CardItem-idea">
                              {post.frontmatter.catch}
                            </p>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    ) : null
                  }
                </Grid>
                ))
              }
            </Grid>
          </Container>
        </section>

      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            catch
            tags
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  }
`
